import Vue from 'vue'
import Router from 'vue-router'


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)
const router =  new Router({
  // mode: 'history',
  // base: '/',
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import(/* webpackChunkName: 'index' */ '@/views/index/')
    },    
    {
      path: '/colorful',
      name: 'colorful',
      component: () => import(/* webpackChunkName: 'recommend' */ '@/views/colorful/')
    },
    {
      path: '/colorfulcenter',
      name: 'colorfulcenter',
      component: () => import(/* webpackChunkName: 'recommend' */ '@/views/colorful/center')
    },
    {
      path: '/colorfuldetail',
      name: 'colorfuldetail',
      component: () => import(/* webpackChunkName: 'recommend' */ '@/views/colorful/detail')
    },
    {
      path: '/recommend',
      name: 'recommend',
      component: () => import(/* webpackChunkName: 'recommend' */ '@/views/recommend/')
    },
    {
      path: '/product',
      name: 'product',
      component: () => import(/* webpackChunkName: 'product' */ '@/views/product/')
    },
    {
      path: '/prdetail',
      name: 'prdetail',
      component: () => import(/* webpackChunkName: 'prdetail' */ '@/views/product/detail')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import(/* webpackChunkName: 'news' */ '@/views/news/index')
    },
    {
      path: '/detail',
      name: 'detail',
      component: () => import(/* webpackChunkName: 'news' */ '@/views/news/detail')
    },  
    {
      path: '/vedio',
      name: 'vedio',
      component: () => import(/* webpackChunkName: 'vedio' */ '@/views/news/vedio')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import(/* webpackChunkName: 'news' */ '@/views/contact/')
    },
    {
      path: '/m/index',
      name: 'mindex',
      component: () => import(/* webpackChunkName: 'news' */ '@/views/mobile/index/')
    }
  ],
  // 当切换到新路由时，想要页面滚到顶部
  scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
          return savedPosition
      } else {
          return { x: 0, y: 0 }
      }
  }
})

export default  router