import Vue from 'vue'
import router from './router'
import store from '@/store'
import App from './App.vue'

// 引用fullpage 插件
import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'
Vue.use(VueFullPage);
// 动画
// import animated from 'animate.css'
import Meta from 'vue-meta'

Vue.use(Meta)
Vue.config.productionTip = false


new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')