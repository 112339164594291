<template>
  <div id="app">
      <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  components: {
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'margin:0')
  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(/(iOS|phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (flag) {
        this.$store.state.isMobile = 'mobile'
        console.log("手机端");
        window.open("http://www.tiborang.com/m/#/");
        return false; // 手机端
      } else {
        this.$store.state.isMobile = 'pc'
        console.log("PC端");
        // this.$router.replace('/');
      }
    }
  },
  mounted(){  

    // 浏览器自适应跳转
    this._isMobile()
    // console.log('this.$store.state.isMobile --', this.$store.state.isMobile);  
    // if (!this.$store.state.isMobile) {
    //   this._isMobile()
    //   console.log('this.$store.state.isMobile --', this.$store.state.isMobile);
    // }
  },
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    // if (sessionStorage.getItem('store')) {
    //   this.$store.replaceState(
    //     Object.assign(
    //       {},
    //       this.$store.state,
    //       JSON.parse(sessionStorage.getItem('store'))
    //     )
    //   )
    // } 
    // // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // // beforeunload事件在页面刷新时先触发
    // window.addEventListener('beforeunload', () => {
    //   sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    // })
  }
}
</script>

<style scoped>
  #app {
    width: 100%;
    height: 100%;
    background: #f5f7fa;
    display: flex;
    align-items: center;
  }
</style>
