import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const config = {
  state: { 
    color:'#59c9c5',
    isMobile: null
  },
  mutations:{},
  modules: {}
}
const store = new Vuex.Store(config)

export default store
